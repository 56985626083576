import { IMGS_PATH } from 'constants/image';
import clsx from 'clsx';
import { Fragment } from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { getStaffFullName } from 'utils/staff';
import Icon from '../Icon';
import Image from '../Image';
import Link from '../Link';
import Socials from '../Socials';
import Stars from '../Stars';
import TrustBox from '../TrustBox';
import styles from './Footer.module.scss';
import type { FooterColType, FooterItemType } from 'types/Footer';

type Props = {
  fields: FooterColType | FooterItemType;
};

const ExtendedFooter = ({ fields }: Props): JSX.Element => {
  const { locale, t } = useTranslation();
  const FOOTER_LINK_COLUMNS = 4;

  return (
    <div className={styles.grid}>
      <div className={styles.column}>
        <h3 className={styles.columnHeader}>{t('footer.resources')}</h3>
        <ul className={styles.columnNavigation}>
          <li>
            <Link href={`/${locale}/${t('header.howItWorks.link')}`}>
              <a className={styles.navigationItem}>{t('header.howItWorks')}</a>
            </Link>
          </li>
          <li>
            <Link href={`/${locale}/guides`}>
              <a className={styles.navigationItem}>{t('guides')}</a>
            </Link>
          </li>
          <li>
            <Link href={`/${locale}/blog`}>
              <a className={styles.navigationItem}>Blog</a>
            </Link>
          </li>
          <li>
            <Link href={`/${locale}/${t('footer.contactUs.link')}`}>
              <a className={styles.navigationItem}>{t('footer.contactUs')}</a>
            </Link>
          </li>
        </ul>
      </div>
      {[...Array(FOOTER_LINK_COLUMNS)].map((_, i) => {
        // eslint-disable-next-line
        // @ts-ignore
        const footerItem = fields.items?.[0];
        const footerColumnItems = footerItem[`footerCol${i + 1}`].items;

        return (
          <Fragment key={`footer_columns_${i}`}>
            {footerColumnItems.length && (
              <div className={styles.column}>
                <h3 className={styles.columnHeader}>
                  {footerItem[`headlineColumn${i + 1}`]}
                </h3>
                <ul className={styles.columnNavigation}>
                  {footerColumnItems.map(
                    // eslint-disable-next-line
                    // @ts-ignore
                    (item, itemIndex: number) => {
                      if (!item.slug && !item.title) {
                        return null;
                      }

                      let link = '';
                      let title = '';

                      switch (item.__typename) {
                        case 'BlockLink':
                        case 'GeneralPage':
                          link = item.slug;
                          break;
                        case 'BlogPage':
                          link = `blog/${item.slug}`;
                          break;
                        case 'GuidePage':
                          link = `guides/${item.slug}`;
                          break;
                        case 'DoctorProfile':
                          link = item.slug;
                          title = getStaffFullName(item);
                          break;
                        case 'SpecialityPage':
                          link = item.slug;
                          title = item.specialityName;
                          break;
                        case 'TreatmentPage':
                          link = `${item.speciality.slug}/${item.slug}`;
                          title = item.treatmentName;
                          break;
                      }

                      const linkTitle = title || item.linkTitle || item.title;

                      return (
                        <li key={`column_entry_${itemIndex}`}>
                          <Link
                            href={`${
                              link.match('http') ? link : `/${locale}/${link}`
                            }`}
                          >
                            <a
                              title={linkTitle}
                              className={styles.navigationItem}
                            >
                              {linkTitle}
                            </a>
                          </Link>
                        </li>
                      );
                    },
                  )}
                </ul>
              </div>
            )}
          </Fragment>
        );
      })}

      <div className={styles.bottom}>
        <div className={styles.socialsContainer}>
          <Socials className={styles.socials} />
        </div>
        <div className={styles.madeInGermany}>
          <div className={styles.madeInGermanyIcon}>
            <Icon type="Countries.DE" />
          </div>
          <span>{t('madeInGermany')}</span>
        </div>
        <div className={styles.bottomGrid}>
          <div className="mb-6">
            <div className={styles.reviews}>
              <div className={styles.trustboxWrapper}>
                <TrustBox
                  className={styles.trustbox}
                  height={90}
                  theme="dark"
                  templateId="53aa8807dec7e10d38f59f32"
                />
              </div>
              <div className={styles.google}>
                <p className={styles.googleHeadline}>Google</p>
                <p className={styles.googleReview}>
                  <span>4.6</span>
                  <Stars rating={4.6} className="mx-1" color="#e7711b" />
                </p>

                <p className={styles.googleBased}>
                  {t('googleReviews.based', { count: 100 })}
                </p>
              </div>
            </div>
            <p className={styles.bottomGridText}>
              {t('verifiedPatientReviews')}
            </p>
            <p>{t('verifiedPatientReviews.text')}</p>
          </div>
          <div className="mb-6">
            <div className="flex justify-center mb-1 lg:h-24 sm:justify-start md:justify-center">
              <div>
                <Image
                  className={styles.imtj}
                  src={`${IMGS_PATH.PUBLIC}/imtj_new.png`}
                  alt="IMTJ"
                  height={84}
                  width={64}
                />
              </div>
            </div>
            <p className={styles.bottomGridText}>
              {t('medicalTravelSpecialist')}
            </p>
            <p>{t('certifiedMedicalTravel')}</p>
          </div>
          <div className={styles.certified}>
            <div className={styles.certifiedImages}>
              <div className={styles.certifiedImageWrapper}>
                <Image
                  className={clsx(styles.certifiedImage, 'mr-1')}
                  src={`${IMGS_PATH.PUBLIC}/logo-norton-secured.png`}
                  alt="Norton Secured"
                  height={70}
                  width={128}
                />
              </div>
              <div>
                <Image
                  className={styles.certifiedImage}
                  src={`${IMGS_PATH.PUBLIC}/trust-lock-badge.png`}
                  alt="TrustLock"
                  height={47}
                  width={128}
                />
              </div>
            </div>
            <p className={styles.bottomGridText}>
              {t('certifiedOnlineSecurity')}
            </p>
            <p>{t('certifiedOnlineSecurity.text')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendedFooter;
